import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewPackage,
  updatePackageById,
  viewPackageById,
} from '../../services/content.service';
import Status from '../form-elements/status.component';
import { CONTENT_ALIAS } from '../../config/const.config';
import PackageType from '../form-elements/package-type.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditPackages = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      sku: '',
      description: '',
      accessType: '',
      subscriptionDays: '',
      maxSubscriptionDays: '',
      autoCreate: false,
      price: '',
      status: null,
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [helperTextSKU, setHelperTextSKU] = useState(null);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const watchAutoCreate = watch('autoCreate');

  const saveNewData = (payload) => {
    createNewPackage(payload)
      .then(() => {
        onSuccess(`New ${CONTENT_ALIAS.PACKAGE_SINGULAR} added successfully.`);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updatePackageById(dataId, payload)
      .then(() => {
        onSuccess(
          `${CONTENT_ALIAS.PACKAGE_SINGULAR} details updated successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      name: data.name,
      sku: data.sku,
      description: data.description,
      accessType: data.accessType,
      subscriptionDays: data.subscriptionDays,
      maxSubscriptionDays: data.maxSubscriptionDays,
      autoCreate: data.autoCreate,
      productId: data.productId || '',
      priceId: data.priceId || '',
      price: Number(data.price),
      status: data.status,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setHelperTextSKU("You won't be able to change SKU later on.");
      setLoading(false);
      return;
    }
    viewPackageById(dataId)
      .then((res) => {
        // Fill form values
        setValue('name', res.data?.name || '');
        setValue('sku', res.data?.sku || '');
        setValue('description', res.data?.description || '');
        setValue('accessType', res.data?.accessType || '');
        setValue('subscriptionDays', res.data?.subscriptionDays || 0);
        setValue('maxSubscriptionDays', res.data?.maxSubscriptionDays || 0);
        setValue('productId', res.data?.productId || '');
        setValue('priceId', res.data?.priceId || '');
        setValue('price', res.data?.price || 0);
        setValue('status', res.data?.status || 0);

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(80) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`${CONTENT_ALIAS.PACKAGE_SINGULAR} Name`}
                      type="text"
                      error={!!errors.name}
                      helperText={errors?.name?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 80 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      type="text"
                      error={!!errors.description}
                      helperText={errors?.description?.message || null}
                      fullWidth
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="subscriptionDays"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(5) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Subscription Days"
                      type="number"
                      error={!!errors.subscriptionDays}
                      helperText={errors?.subscriptionDays?.message || null}
                      fullWidth
                      inputProps={{
                        min: 0,
                        max: 20000,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="maxSubscriptionDays"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(5) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Max Subscription Days"
                      type="number"
                      error={!!errors.maxSubscriptionDays}
                      helperText={errors?.maxSubscriptionDays?.message || null}
                      fullWidth
                      inputProps={{
                        min: 0,
                        max: 20000,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="accessType"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <PackageType
                      id="accessType"
                      name="accessType"
                      label="Access Type"
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.accessType?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="sku"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(50) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`${CONTENT_ALIAS.PACKAGE_SINGULAR} SKU`}
                      type="text"
                      error={!!errors.sku}
                      helperText={errors?.sku?.message || helperTextSKU}
                      fullWidth
                      inputProps={{ maxLength: 50 }}
                      disabled={!!dataId}
                    />
                  )}
                />
              </Grid>
              {!dataId && (
                <Grid item xs={12} sm={12} md={12} sx={{ mt: 1 }}>
                  <Controller
                    name="autoCreate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="autoCreate"
                            onChange={onChange}
                            value={value}
                            defaultChecked={false}
                          />
                        }
                        label="Auto create stripe with priceless"
                      />
                    )}
                  />
                </Grid>
              )}
              {!watchAutoCreate && (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="productId"
                      control={control}
                      rules={{
                        ...validationRules.REQUIRED,
                        ...{ ...maxLengthValidation(50) },
                        validate: {
                          startsWithProd: (value) =>
                            value.startsWith('prod_') ||
                            "Product ID must start with 'prod_'",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Stripe Product ID"
                          type="text"
                          error={!!errors.productId}
                          helperText={errors?.productId?.message || null}
                          fullWidth
                          inputProps={{ maxLength: 50 }}
                          disabled={dataId}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="priceId"
                      control={control}
                      rules={{
                        ...validationRules.REQUIRED,
                        ...{ ...maxLengthValidation(50) },
                        validate: {
                          startsWithPrice: (value) =>
                            value.startsWith('price_') ||
                            "Price ID must start with 'price_'",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Stripe Price ID"
                          type="text"
                          error={!!errors.priceId}
                          helperText={errors?.priceId?.message || null}
                          fullWidth
                          inputProps={{ maxLength: 50 }}
                          disabled={dataId}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="price"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(8) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Price (CAD)"
                      type="number"
                      error={!!errors.price}
                      helperText={errors?.price?.message || null}
                      fullWidth
                      disabled={dataId}
                      inputProps={{
                        min: 0,
                        max: 99999,
                        step: 'any',
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="sub_status"
                      name="sub_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditPackages.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditPackages;
