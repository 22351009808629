import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Grid,
  TextField,
  Box,
} from '@mui/material';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import validationRules from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewGroupMember,
  updateGroupMemberById,
  viewGroupMemberById,
} from '../../services/content.service';
import Datepicker from '../form-elements/datepicker.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditMember = ({
  title,
  dataId,
  conversationId,
  onCancel,
  onSuccess,
  snackbarMessageCallback,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      member: '',
      membershipEndDate: null,
    },
  });
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [memberEmails, setMemberEmails] = useState({
    valid: [],
    invalid: [],
  });

  const saveNewData = (payload) => {
    createNewGroupMember(payload)
      .then((res) => {
        setMemberEmails({
          valid: res.data?.validEmails,
          invalid: res.data?.invalidEmails,
        });
        onSuccess('', false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateGroupMemberById(conversationId, dataId, payload)
      .then(() => {
        onSuccess('Member details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = async (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const membershipEndDateGMT =
      Date.parse(data.membershipEndDate?.$d.toISOString()) / 1000;

    const members = data.member
      .trim()
      .split('\n')
      .map((email) => email.trim());

    const payload = {
      conversationId,
      members,
      membershipEndDate: membershipEndDateGMT,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewGroupMemberById(conversationId, dataId)
      .then((res) => {
        // Fill form values
        setValue('member', res?.data?.email || '');
        setValue('membershipEndDate', dayjs.unix(res?.data?.ed) || null);

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth={
        memberEmails.valid?.length > 0 || memberEmails.invalid?.length > 0
          ? 'md'
          : 'lg'
      }
    >
      <DialogTitle id="view-dialog-title">
        {title}
        {(memberEmails.valid?.length > 0 || memberEmails.invalid?.length > 0) &&
          ' - Summary'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              {!memberEmails.valid?.length && !memberEmails.invalid?.length && (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="member"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Members (Email)"
                          type="text"
                          multiline
                          minRows={!dataId && 5}
                          maxRows={15}
                          onChange={onChange}
                          value={value}
                          error={!!errors.member}
                          helperText={errors?.member?.message || null}
                          disabled={dataId}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-8px' }}>
                    <Controller
                      name="membershipEndDate"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field: { onChange, value } }) => (
                        <Datepicker
                          id="membershipEndDate"
                          name="membershipEndDate"
                          label="Membership End Date"
                          value={value}
                          defaultValue={!dataId ? null : value}
                          onChange={onChange}
                          error={errors?.membershipEndDate?.message || ''}
                          disablePast
                          sx={{ paddingTop: 0, width: '100%' }}
                        />
                      )}
                    />
                  </Grid>
                  {!dataId && (
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: 2,
                      }}
                    >
                      <Box sx={{ fontWeight: 'bold' }}>Note:&nbsp;</Box>
                      You can add multiple email at a time. But enter one email
                      per line.
                    </Grid>
                  )}
                </>
              )}

              {(memberEmails.valid?.length > 0 ||
                memberEmails.invalid?.length > 0) && (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box marginY={1}>
                      Already added/Existing members:{' '}
                      {memberEmails.valid?.length || 0}
                      <CopyToClipboard text={memberEmails.valid?.join('\n')}>
                        <IconButton
                          aria-label="close"
                          onClick={() =>
                            snackbarMessageCallback(
                              'success',
                              'Text copied to clipboard.'
                            )
                          }
                          size="small"
                          sx={{ color: (theme) => theme.palette.grey[500] }}
                        >
                          <Iconify icon="solar:copy-bold" />
                        </IconButton>
                      </CopyToClipboard>
                    </Box>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        label="Already added/Existing members"
                        type="text"
                        multiline
                        minRows={5}
                        maxRows={25}
                        value={memberEmails.valid?.join('\n')}
                        disabled
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Box marginY={1}>
                      Emails not registered: {memberEmails.invalid?.length || 0}
                      <CopyToClipboard text={memberEmails.invalid?.join('\n')}>
                        <IconButton
                          aria-label="close"
                          onClick={() =>
                            snackbarMessageCallback(
                              'success',
                              'Text copied to clipboard.'
                            )
                          }
                          size="small"
                          sx={{ color: (theme) => theme.palette.grey[500] }}
                        >
                          <Iconify icon="solar:copy-bold" />
                        </IconButton>
                      </CopyToClipboard>
                    </Box>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        label="Emails not registered"
                        type="text"
                        multiline
                        minRows={5}
                        maxRows={25}
                        value={memberEmails.invalid?.join('\n')}
                        disabled
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        )}
      </DialogContent>

      {!memberEmails.valid?.length && !memberEmails.invalid?.length && (
        <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
          >
            Save
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
          >
            Cancel
          </Button>
        </DialogActions>
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditMember.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  conversationId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  snackbarMessageCallback: PropTypes.func.isRequired,
};

export default AddEditMember;
